@import "primeng/resources/primeng.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
/* @import "bh-theme-primeng.css"; */
@import "node_modules/primeflex/primeflex.css";
@import "primeicons/primeicons.css";
@import "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap";

body,
body .p-component,
body .p-element {
  font-size: 13px;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.new_tag {
  background-color: #59c3c3 !important;
}

.improved_tag {
  background-color: #52489c !important;
}

.fixed_tag {
  background-color: #f45b69 !important;
}

.bh-bg-orange {
  background-color: #003974 !important;
}

.bh-bg-orange:hover {
  background-color: white !important;
}

.bh-color-primary {
  color: #003974 !important;
}

.bh-color-primary {
  background-color: #003974 !important;
}


.border-white {
  border-color: white !important;
}

.border-white:hover {
  border-color: #003974 !important;
}